import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";

import * as React from "react";
import Shell from "../layout/shell";

import rightArrow from "../assets/images/right-arrow.svg";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";

import * as styles from "./index.module.scss";
import { PrismicRichText } from "@prismicio/react";
import { ClassNames } from "@rocketmakers/armstrong-edge";

const Index: React.FC<PageProps<Queries.IndexPageQuery>> = (pageProps) => {
  const homePagedata = pageProps.data.homePage?.data;
  const caseStudiesData = pageProps.data.caseStudies.nodes;
  if (!homePagedata) {
    return null;
  }

  return (
    <Shell>
      <div className={ClassNames.concat(styles.homePage, "fixed-width")}>
        <h1>{homePagedata?.title}</h1>
        <p className={styles.intro}>{homePagedata?.intro}</p>
        <Swiper
          className={styles.hero}
          modules={[EffectFade, Autoplay]}
          autoplay
          speed={600}
          loop
          effect={"fade"}
        >
          {homePagedata.hero_gallery?.map((item) => {
            if (!item || !item.image) {
              return null;
            }
            const image = getImage(item.image.gatsbyImageData);
            return (
              <SwiperSlide>
                {image && (
                  <GatsbyImage image={image} alt={item.image?.alt || ""} />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className={styles.workHeader}>
          <h2>Recent work</h2>
          <Link className="no-mobile" to="/work">
            <img src={rightArrow} />
            View all work
          </Link>
        </div>
        <div className={styles.caseStudies}>
          {caseStudiesData.map((caseStudy) => {
            const coverImage =
              caseStudy.data.cover &&
              getImage(caseStudy.data.cover?.gatsbyImageData);
            return (
              <Link key={caseStudy.uid} to={`/work/${caseStudy.uid}`}>
                {coverImage && (
                  <GatsbyImage
                    image={coverImage}
                    alt={caseStudy.data.cover.alt || ""}
                  />
                )}
                <h4>{`Case study: ${caseStudy.data.title}`}</h4>
                <p>{caseStudy.data.snippet}</p>
                <div className={styles.learnMoreLink}>Learn more</div>
              </Link>
            );
          })}
        </div>
        <div className={styles.workHeaderSlim}>
          <h2>Organisations I’ve worked with</h2>
          <Link to="/work">
            <img src={rightArrow} />
            View all work
          </Link>
        </div>
        <div className={styles.workedWith}>
          {homePagedata.people_worked_with?.map((item) => (
            <div>
              <strong>{item?.category}</strong>
              <PrismicRichText
                field={item?.names?.richText}
                components={{
                  hyperlink: ({ children, node }) => (
                    <Link to={`/work/${(node.data as any).slug}`}>
                      {children}
                    </Link>
                  ),
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </Shell>
  );
};

export const query = graphql`
  query IndexPage {
    homePage: prismicHomePage {
      data {
        title
        intro
        hero_gallery {
          image {
            gatsbyImageData
            alt
          }
        }
        people_worked_with {
          category
          names {
            richText
          }
        }
      }
    }
    caseStudies: allPrismicCaseStudy(
      filter: { data: { featured: { eq: true } } }
      sort: { fields: data___featured_order }
    ) {
      nodes {
        uid
        data {
          title
          snippet
          featured
          cover {
            alt
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default Index;
